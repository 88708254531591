const SIDEBARBTN = [
  {
    name: "خانه",
    location: "/home",
    icon: "home.png",
  },
  {
    name: "ورزشکاران",
    location: "/athlete",
    icon: "athlete.png",
  },
  {
    name: "صندوق لباس",
    location: "/closet",
    icon: "closets.png",
  },
  {
    name: "فیس ورزشکار",
    location: "/payment",
    icon: "fees.png",
  },
  {
    name: "فیس صندوق",
    location: "/closetPayment",
    icon: "fees.png",
  },
  {
    name: "مصارف",
    location: "/expend",
    icon: "expend.png",
  },
  {
    name: "وسایل",
    location: "/equipment",
    icon: "vahical.png",
  },
  {
    name: "کاربران",
    location: "/users",
    icon: "users.png",
  },
  // {
  //   name: "روزنامچه",
  //   location: "/roznamcha",
  //   icon: "report.png",
  // },
  {
    name: "درباره ما",
    location: "/about",
    icon: "about.png",
  },
];
const URL = "https://gympowerup-backend.techlands.online/";
module.exports = {
  SIDEBARBTN,
  URL,
};
