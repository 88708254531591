import { Link, useLocation } from "react-router-dom";
import "../Styles/Components/SidebarBtn.css";
import { SIDEBARBTN } from "../Constants/constant";

export function SidebarBtn(props) {
  const location = useLocation();
  let btnLocation = location.pathname;
  if (btnLocation == "/") {
    btnLocation = "/home";
  }
  return (
    <>
      {SIDEBARBTN.map((btn, index) => {
        return (
          <Link
            key={index}
            className={
              btn.location == btnLocation
                ? "sidebarBtn-containerActive"
                : "sidebarBtn-container"
            }
            to={btn.location}
          >
            <img src={btn.icon} alt="icon" />
            <span>{btn.name}</span>
          </Link>
        );
      })}
    </>
  );
}
