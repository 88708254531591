import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
export function Closet() {
  const months = ["حمل", "ثور", "جوزا", "سرطان", "اسد", "سنبله", "میزان", "عقرب", "قوس", "جدی", "دلو", "حوت"]
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const [closets, setClosets] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("");
  const [filteredCloset, setFilteredCloset] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderClosets();
    loaderAthletes();
    todayDate();
  }, [refresh]);
  const loaderClosets = async () => {
    try {
      const closet = await axios.get(`${URL}closet`);
      if (closet) {
        setClosets(closet.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const todayDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  };
  const loaderAthletes = async () => {
    try {
      const athlete = await axios.get(`${URL}athlete`);
      if (athlete) {
        setAthletes(athlete.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addClosetHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newCloset = {
      number: Number(e.target.number.value),
      cost: Number(e.target.cost.value),
      athleteId: Number(e.target.athleteId.value),
      closetStatus: "FILL",
      description: e.target.description.value,
      fullName: e.target.fullName.value,
      startDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
      paymentDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
    };
    try {
      await axios.post(`${URL}insertCloset`, newCloset);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (closetId) => {
    console.log("closetId", closetId);
    try {
      await axios.delete(`${URL}deleteCloset/${closetId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (closetId) => {
    console.log("closetId", closetId);
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filtereCloset = closets.filter((closet) => closet.id == closetId);
    setFilteredCloset(filtereCloset[0]);
  };
  const updateClosetHandler = async (e) => {
    e.preventDefault();
    const EditedCloset = {
      id: filteredCloset.id,
      number: Number(e.target.number.value),
      cost: Number(e.target.cost.value),
      athleteId: Number(e.target.athleteId.value),
      closetStatus: filteredCloset.closetStatus,
      description: e.target.description.value,
      fullName: e.target.fullName.value,
    };
    console.log("editcloset", EditedCloset);
    const id = filteredCloset.id;
    try {
      await axios.put(`${URL}updateCloset/${id}`, EditedCloset);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedLabel = selectedOption.textContent;
    setSelectedOptionLabel(selectedLabel);
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add user */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addClosetHandler}>
              <div className="addPopup-header">
                <span>صندوق جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="number"
                  name="number"
                  id="number"
                  placeholder="صندوق"
                  className="text-input"
                />
                <input
                  type="number"
                  name="cost"
                  id="cost"
                  placeholder="قیمت"
                  className="text-input"
                />
                <select
                  onChange={handleSelectChange}
                  name="athleteId"
                  id="athleteId"
                  className="select-btn2"
                  placeholder="نام"
                >
                  <option value="empty">ورزشکار را انتخاب کنید</option>
                  {athletes.map((athlete, index) => {
                    return (
                      <option value={athlete.id} key={index}>
                        {athlete.firstName + " " + athlete.lastName}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="hidden"
                  name="fullName"
                  id="fullName"
                  className="text-input"
                  defaultValue={selectedOptionLabel}
                />
                <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                />
                <button className="add-btn" type="submit">
                  ثبت صندوق
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updateClosetHandler}>
              <div className="addPopup-header">
                <span>صندوق را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="number"
                  name="number"
                  id="number"
                  placeholder="صندوق"
                  className="text-input"
                  defaultValue={filteredCloset.number}
                />
                <input
                  type="number"
                  name="cost"
                  id="cost"
                  placeholder="قیمت"
                  className="text-input"
                  defaultValue={filteredCloset.cost}
                />
                <select
                  onChange={handleSelectChange}
                  name="athleteId"
                  id="athleteId"
                  className="select-btn2"
                  defaultValue={selectedOptionLabel}
                >
                  <option value="empty">ورزشکار را انتخاب کنید</option>
                  {athletes.map((athlete, index) => {
                    return (
                      <option value={athlete.id} key={index}>
                        {athlete.firstName + " " + athlete.lastName}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="hidden"
                  name="fullName"
                  id="fullName"
                  className="text-input"
                  defaultValue={selectedOptionLabel}
                />
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                  defaultValue={filteredCloset.description}
                />
                <button className="add-btn" type="submit">
                  ویرایش صندوق
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="صندوق کاربر را جستجو کنید ..."
            autoComplete="off"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="closetStatus"
            id="closetStatus"
            className="select-btn"
          >
            <option value="">همه وضیعت ها</option>
            <option value="EMPTY">خالی</option>
            <option value="FILL">پر</option>
          </select>
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            + افزودن صندوق
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>صندوق</th>
                <th>قیمت</th>
                <th>مالک</th>
                <th>وضیعت</th>
                <th>توضیحات</th>
                <th>تاریخ ثبت</th>
                <th>تاریخ فیس</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {closets.map((closet, index) => {
                if (
                  closet.closetStatus?.includes(search) ||
                  closet.name?.includes(search)
                ) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{closet.number}</td>
                      <td>{closet.cost}</td>
                      <td>{closet.name}</td>
                      <td>
                        <span
                          className={
                            closet.closetStatus == "FILL"
                              ? "active"
                              : "disActive"
                          }
                        >
                          {closet.closetStatus == "FILL" ? "پر" : "خالی"}
                        </span>
                      </td>
                      <td>{closet.description}</td>
                      <td>{closet.startDate.slice(0, 10)}</td>
                      <td>{closet.paymentDate.slice(0, 10)}</td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={closet.id}
                            className="action-btn"
                            onClick={() => handleDelete(closet.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(closet.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
