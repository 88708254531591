import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
export function User() {
  const [users, setUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredUser, setFilteredUser] = useState();
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderUsers();
  }, [refresh]);
  const loaderUsers = async () => {
    try {
      const user = await axios.get(`${URL}users`);
      if (user) {
        setUsers(user.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addUserHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newUser = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      userName: e.target.userName.value,
      password: e.target.password.value,
      role: e.target.role.value,
    };
    try {
      await axios.post(`${URL}insertUser`, newUser);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${URL}deleteUser/${userId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (userId) => {
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filtereUser = users.filter((user) => user.id == userId);
    setFilteredUser(filtereUser[0]);
  };
  const updateUserHandler = async (e) => {
    e.preventDefault();
    const EditedUser = {
      id: filteredUser.id,
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      userName: e.target.userName.value,
      password: e.target.password.value,
      role: e.target.role.value,
    };
    const id = filteredUser.id;
    try {
      await axios.put(`${URL}updateUser/${id}`, EditedUser);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add user */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addUserHandler}>
              <div className="addPopup-header">
                <span>کاربر جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="نام"
                  className="text-input"
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="تخلص"
                  className="text-input"
                />
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="نام کاربری"
                  className="text-input"
                />
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="پسورد"
                  className="text-input"
                />
                <select name="role" id="role" className="select-btn2">
                  <option value="ADMIN">ادمین</option>
                  <option value="STANDARD">عادی</option>
                </select>
                <button className="add-btn" type="submit">
                  ثبت کاربر
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updateUserHandler}>
              <div className="addPopup-header">
                <span>کاربر را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="نام"
                  className="text-input"
                  defaultValue={filteredUser.firstName}
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="تخلص"
                  className="text-input"
                  defaultValue={filteredUser.lastName}
                />
                <input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="نام کاربری"
                  className="text-input"
                  defaultValue={filteredUser.userName}
                />
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="پسورد"
                  className="text-input"
                  defaultValue={filteredUser.password}
                />
                <select
                  name="role"
                  id="role"
                  className="select-btn2"
                  defaultValue={filteredUser.role}
                >
                  <option value="ADMIN">ادمین</option>
                  <option value="STANDARD">عادی</option>
                </select>
                <button className="add-btn" type="submit">
                  ویرایش کاربر
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="نام کاربر را جستجو کنید ..."
            autoComplete="off"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="role"
            id="role"
            className="select-btn"
          >
            <option value="">همه رول</option>
            <option value="ADMIN">ادمین</option>
            <option value="STANDARD">عادی</option>
          </select>
          {userDetail?.role == "ADMIN" && (
            <button
              className="add-btn"
              onClick={() => {
                setShowDark(!showDark);
                setShowPopup(!showPopup);
              }}
            >
              + افزودن کاربر
            </button>
          )}
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام</th>
                <th>تخلص</th>
                <th>نام کاربری</th>
                <th>پسورد</th>
                <th>رول</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => {
                if (
                  user.firstName?.includes(search) ||
                  user.role?.includes(search)
                ) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>
                        {userDetail?.role == "ADMIN" ? user.userName : "🔒"}
                      </td>
                      <td>
                        {userDetail?.role == "ADMIN" ? user.password : "🔒"}
                      </td>
                      <td>
                        <span
                          className={
                            user.role == "ADMIN" ? "active" : "disActive"
                          }
                        >
                          {user.role == "ADMIN" ? "ادمین" : "عادی"}
                        </span>
                      </td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={user.id}
                            className="action-btn"
                            onClick={() => handleDelete(user.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(user.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
