import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
export function Payment() {
  const months = ["حمل", "ثور", "جوزا", "سرطان", "اسد", "سنبله", "میزان", "عقرب", "قوس", "جدی", "دلو", "حوت"]
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const [payments, setPayments] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("");
  const [filteredPayment, setFilteredPayment] = useState();
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderPayments();
    loaderAthletes();
  }, [refresh]);
  const loaderPayments = async () => {
    try {
      const payment = await axios.get(`${URL}payment`);
      if (payment) {
        setPayments(payment.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loaderAthletes = async () => {
    try {
      const athlete = await axios.get(`${URL}athlete`);
      if (athlete) {
        setAthletes(athlete.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addPaymentHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newPayment = {
      athleteId: Number(e.target.athleteId.value),
      amount: Number(e.target.amount.value),
      paymentDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
      fullName: e.target.fullName.value,
    };
    try {
      await axios.post(`${URL}insertPayment`, newPayment);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (paymentId) => {
    console.log("paymentId", paymentId);
    try {
      await axios.delete(`${URL}deletePayment/${paymentId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (paymentId) => {
    console.log("paymentId", paymentId);
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filterePayment = payments.filter(
      (payment) => payment.id == paymentId
    );
    setFilteredPayment(filterePayment[0]);
    console.log("paymentId", filteredPayment);
  };
  const updatePaymentHandler = async (e) => {
    e.preventDefault();
    const EditedPayment = {
      id: filteredPayment.id,
      athleteId: Number(e.target.athleteId.value),
      amount: Number(e.target.amount.value),
      paymentDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
    };
    const id = filteredPayment.id;
    try {
      await axios.put(`${URL}updatePayment/${id}`, EditedPayment);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedLabel = selectedOption.textContent;
    setSelectedOptionLabel(selectedLabel);
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add user */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addPaymentHandler}>
              <div className="addPopup-header">
                <span>فیس جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <select
                  onChange={handleSelectChange}
                  name="athleteId"
                  id="athleteId"
                  className="select-btn2"
                  placeholder="نام"
                >
                  <option value="empty">ورزشکار را انتخاب کنید</option>
                  {athletes.map((athlete, index) => {
                    return (
                      <option value={athlete.id} key={index}>
                        {athlete.firstName + " " + athlete.lastName}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="hidden"
                  name="fullName"
                  id="fullName"
                  className="text-input"
                  defaultValue={selectedOptionLabel}
                />
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="مبلغ"
                  className="text-input"
                />
                 <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                <button className="add-btn" type="submit">
                  ثبت فیس
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updatePaymentHandler}>
              <div className="addPopup-header">
                <span>فیس را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <select
                  onChange={handleSelectChange}
                  name="athleteId"
                  id="athleteId"
                  className="select-btn2"
                  placeholder="نام"
                >
                  <option value="empty">ورزشکار را انتخاب کنید</option>
                  {athletes.map((athlete, index) => {
                    return (
                      <option value={athlete.id} key={index}>
                        {athlete.firstName + " " + athlete.lastName}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="مبلغ"
                  className="text-input"
                  defaultValue={filteredPayment.amount}
                />
                <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={filteredPayment.paymentDate.slice(0, 10)}
                        id="inputDate"
                        name="inputDate"
                      />
                <button className="add-btn" type="submit">
                  ویرایش فیس
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="نام کاربر را جستجو کنید ..."
            autoComplete="off"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            + افزودن فیس
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام</th>
                <th>مبلغ</th>
                <th>تاریخ</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => {
                if (payment.name?.includes(search)) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{payment.name}</td>
                      <td>{payment.amount}</td>
                      <td>{payment.paymentDate.slice(0, 10)}</td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={payment.id}
                            className="action-btn"
                            onClick={() => handleDelete(payment.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(payment.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
