import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
export function Equipment() {
  const [equipments, setEquipments] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredEquipment, setFilteredEquipment] = useState();
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderEquipment();
  }, [refresh]);
  const loaderEquipment = async () => {
    try {
      const equipment = await axios.get(`${URL}equipments`);
      if (equipment) {
        setEquipments(equipment.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addEquipmentHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newEquipment = {
      name: e.target.name.value,
      count: e.target.count.value,
      status: e.target.status.value,
      description: e.target.description.value,
    };
    try {
      await axios.post(`${URL}insertEquipment`, newEquipment);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (equipmentId) => {
    try {
      await axios.delete(`${URL}deleteEquipment/${equipmentId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (equipmentId) => {
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filtereEquipment = equipments.filter(
      (equipment) => equipment.id == equipmentId
    );
    setFilteredEquipment(filtereEquipment[0]);
  };
  const updateUserHandler = async (e) => {
    e.preventDefault();
    const EditedEquipment = {
      name: e.target.name.value,
      count: e.target.count.value,
      status: e.target.status.value,
      description: e.target.description.value,
    };
    const id = filteredEquipment.id;
    try {
      await axios.put(`${URL}updateEquipment/${id}`, EditedEquipment);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add user */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addEquipmentHandler}>
              <div className="addPopup-header">
                <span>وسیله جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="نام"
                  className="text-input"
                />
                <input
                  type="number"
                  name="count"
                  id="count"
                  placeholder="تعداد"
                  className="text-input"
                />
                <select
                  name="status"
                  id="status"
                  className="select-btn2"
                  placeholder="وضیعت"
                >
                  <option value="active">فعال</option>
                  <option value="disActive">غیرفعال</option>
                </select>
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                />
                <button className="add-btn" type="submit">
                  ثبت وسیله
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updateUserHandler}>
              <div className="addPopup-header">
                <span>وسیله را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="نام"
                  className="text-input"
                  defaultValue={filteredEquipment.name}
                />
                <input
                  type="number"
                  name="count"
                  id="count"
                  placeholder="تعداد"
                  className="text-input"
                  defaultValue={filteredEquipment.count}
                />
                <select
                  name="status"
                  id="status"
                  className="select-btn2"
                  placeholder="وضیعت"
                  defaultValue={filteredEquipment.status}
                >
                  <option value="active">فعال</option>
                  <option value="disActive">غیرفعال</option>
                </select>
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                  defaultValue={filteredEquipment.description}
                />
                <button className="add-btn" type="submit">
                  ویرایش وسیله
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="نام وسیله را جستجو کنید ..."
            autoComplete="off"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="status"
            id="status"
            className="select-btn"
          >
            <option value="">همه وضیعت ها</option>
            <option value="active">فعال</option>
            <option value="disActive">غیرفعال</option>
          </select>
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            + افزودن وسیله
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام</th>
                <th>تعداد</th>
                <th>وضیعت</th>
                <th>توضیحات</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {equipments.map((equipment, index) => {
                if (
                  equipment.name?.includes(search) ||
                  equipment.status?.includes(search)
                ) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{equipment.name}</td>
                      <td>{equipment.count}</td>
                      <td>
                        <span
                          className={
                            equipment.status == "active"
                              ? "active"
                              : "disActive"
                          }
                        >
                          {equipment.status == "active" ? "فعال" : "غیرفعال"}
                        </span>
                      </td>
                      <td>{equipment.description}</td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={equipment.id}
                            className="action-btn"
                            onClick={() => handleDelete(equipment.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(equipment.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
