import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
export function Expend() {
  const months = ["حمل", "ثور", "جوزا", "سرطان", "اسد", "سنبله", "میزان", "عقرب", "قوس", "جدی", "دلو", "حوت"]
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const [expends, setExpends] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredExpend, setFilteredExpend] = useState();
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderExpends();
  }, [refresh]);
  const loaderExpends = async () => {
    try {
      const expend = await axios.get(`${URL}expend`);
      if (expend) {
        setExpends(expend.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addExpendHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newExpend = {
      amount: Number(e.target.amount.value),
      description: e.target.description.value,
      expendDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
    };
    try {
      await axios.post(`${URL}insertExpend`, newExpend);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (expendId) => {
    console.log("paymentId", expendId);
    try {
      await axios.delete(`${URL}deleteExpend/${expendId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (expendId) => {
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filtereExpend = expends.filter((expend) => expend.id == expendId);
    setFilteredExpend(filtereExpend[0]);
  };
  const updateExpendHandler = async (e) => {
    e.preventDefault();
    const EditedExpend = {
      id: filteredExpend.id,
      amount: Number(e.target.amount.value),
      description: e.target.description.value,
      expendDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
    };
    const id = filteredExpend.id;
    try {
      await axios.put(`${URL}updateExpend/${id}`, EditedExpend);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add user */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addExpendHandler}>
              <div className="addPopup-header">
                <span>مصرف جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="مبلغ"
                  className="text-input"
                />
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                />
                  <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                <button className="add-btn" type="submit">
                  ثبت مصرف
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updateExpendHandler}>
              <div className="addPopup-header">
                <span>فیس را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="مبلغ"
                  className="text-input"
                  defaultValue={filteredExpend.amount}
                />
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="توضیحات"
                  className="text-input"
                  defaultValue={filteredExpend.description}
                />
                <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={filteredExpend.expendDate.slice(0, 10)}
                        id="inputDate"
                        name="inputDate"
                      />
                <button className="add-btn" type="submit">
                  ویرایش مصرف
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder=" مصارف را جستجو کنید ..."
            autoComplete="off"
            className="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            + افزودن مصرف
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>مبلغ</th>
                <th>توضیحات</th>
                <th>تاریخ</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {expends.map((expend, index) => {
                if (expend.description?.includes(search)) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{expend.amount}</td>
                      <td>{expend.description}</td>
                      <td>{expend.expendDate.slice(0, 10)}</td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={expend.id}
                            className="action-btn"
                            onClick={() => handleDelete(expend.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(expend.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
