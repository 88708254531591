import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { DateObject } from "react-multi-date-picker";
export function Athlete() {
  const months = ["حمل", "ثور", "جوزا", "سرطان", "اسد", "سنبله", "میزان", "عقرب", "قوس", "جدی", "دلو", "حوت"]
  const date = new DateObject({ calendar: persian, locale: persian_fa, format: "YYYY-MM-DD" })
  const today = date.format()
  const [athletes, setAthletes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredAthlete, setFilteredAthlete] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [userDetail, setUserDetails] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderAthletes();
    todayDate();
  }, [refresh]);
  const loaderAthletes = async () => {
    try {
      const athlete = await axios.get(`${URL}athlete`);
      if (athlete) {
        setAthletes(athlete.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const todayDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  };
  const addAthleteHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newAthlete = {
      firstName: e.target.firstName.value,
      fatherName: e.target.fatherName.value,
      lastName: e.target.lastName.value,
      age: Number(e.target.age.value),
      phone: e.target.phone.value,
      address: e.target.address.value,
      fess: Number(e.target.fees.value),
      startDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
      paymentDate: e.target.inputDate.value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g,    
      function(a) { return a.charCodeAt(0) & 0xf }     
  ),
      status: "active",
    };
    try {
      await axios.post(`${URL}insertAthlete`, newAthlete);
      setRefresh(!refresh);
      console.log("newAthlete", newAthlete);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (athleteId) => {
    try {
      await axios.delete(`${URL}athlete/${athleteId}`);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (athleteId) => {
    setShowDark(!showDark);
    setShowEditPopup(!showEditPopup);
    const filtereAthlete = athletes.filter(
      (athlete) => athlete.id == athleteId
    );
    setFilteredAthlete(filtereAthlete[0]);
  };
  const updateAthleteHandler = async (e) => {
    e.preventDefault();
    const EditedAthlete = {
      id: filteredAthlete.id,
      firstName: e.target.firstName.value,
      fatherName: e.target.fatherName.value,
      lastName: e.target.lastName.value,
      age: Number(e.target.age.value),
      phone: e.target.phone.value,
      address: e.target.address.value,
      fess: Number(e.target.fees.value),
      // startDate: filteredAthlete.startDate,
      status: filteredAthlete.status,
    };
    // console.log("edit", EditedAthlete);
    const id = filteredAthlete.id;
    try {
      await axios.put(`${URL}updateAthlete/${id}`, EditedAthlete);
      setShowDark(!showDark);
      setShowEditPopup(!showEditPopup);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add athlete */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addAthleteHandler}>
              <div className="addPopup-header">
                <span>ورزشکار جدید را ثبت نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="نام"
                  className="text-input"
                />
                <input
                  type="text"
                  name="fatherName"
                  id="fatherName"
                  placeholder="نام پدر"
                  className="text-input"
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="تخلص"
                  className="text-input"
                />
                <input
                  type="number"
                  name="age"
                  id="age"
                  placeholder="سن"
                  className="text-input"
                />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="موبایل"
                  className="text-input"
                />
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="آدرس"
                  className="text-input"
                />
                <input
                  type="number"
                  name="fees"
                  id="fees"
                  placeholder="فیس"
                  className="text-input"
                />
                <DatePicker
                        calendar={persian}
                        locale={persian_fa}
                        months={months}
                        calendarPosition="top-right"
                        format="YYYY-MM-DD"
                        inputClass="inputDate"
                        value={today}
                        id="inputDate"
                        name="inputDate"
                      />
                <button className="add-btn" type="submit">
                  ثبت ورزشکار
                </button>
              </div>
            </form>
          </div>
        )}
        {/* edit athlete */}
        {showEditPopup && (
          <div className="add-athlete">
            <form onSubmit={updateAthleteHandler}>
              <div className="addPopup-header">
                <span>ورزشکار را ویرایش نمایید</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowEditPopup(!showEditPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="نام"
                  className="text-input"
                  defaultValue={filteredAthlete.firstName}
                />
                <input
                  type="text"
                  name="fatherName"
                  id="fatherName"
                  placeholder="نام پدر"
                  className="text-input"
                  defaultValue={filteredAthlete.fatherName}
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="تخلص"
                  className="text-input"
                  defaultValue={filteredAthlete.lastName}
                />
                <input
                  type="number"
                  name="age"
                  id="age"
                  placeholder="سن"
                  className="text-input"
                  defaultValue={filteredAthlete.age}
                />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="موبایل"
                  className="text-input"
                  defaultValue={filteredAthlete.phone}
                />
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="آدرس"
                  className="text-input"
                  defaultValue={filteredAthlete.address}
                />
                <input
                  type="number"
                  name="fees"
                  id="fees"
                  placeholder="فیس"
                  className="text-input"
                  defaultValue={filteredAthlete.fess}
                />
                <button className="add-btn" type="submit">
                  ویرایش ورزشکار
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="نام ورزشکار را جستجو کنید ..."
            className="search"
            autoComplete="off"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="status"
            id="status"
            className="select-btn"
          >
            <option value="">همه حالات</option>
            <option value="active">فعال</option>
            <option value="disActive">غیرفعال</option>
          </select>
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            + افزودن ورزشکار
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام</th>
                <th>نام پدر</th>
                <th>تخلص</th>
                <th>سن</th>
                <th>موبایل</th>
                <th>آدرس</th>
                <th>فیس</th>
                <th>تاریخ ثبت</th>
                <th>تاریخ فیس</th>
                <th>حالت</th>
                {userDetail?.role == "ADMIN" && <th>عملیات</th>}
              </tr>
            </thead>
            <tbody>
              {athletes.map((athlete, index) => {
                if (
                  athlete.firstName?.includes(search) ||
                  athlete.status?.includes(search)
                ) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{athlete.firstName}</td>
                      <td>{athlete.fatherName}</td>
                      <td>{athlete.lastName}</td>
                      <td>{athlete.age}</td>
                      <td>{athlete.phone}</td>
                      <td>{athlete.address}</td>
                      <td>{athlete.fess}</td>
                      <td>{athlete.startDate.slice(0, 10)}</td>
                      <td>{athlete.paymentDate.slice(0, 10)}</td>
                      <td>
                        <span
                          className={
                            athlete.status == "active" ? "active" : "disActive"
                          }
                        >
                          {athlete.status == "active" ? "فعال" : "غیرفعال"}
                        </span>
                      </td>
                      {userDetail?.role == "ADMIN" && (
                        <td>
                          <img
                            src="delete.png"
                            alt="icon"
                            id={athlete.id}
                            className="action-btn"
                            onClick={() => handleDelete(athlete.id)}
                          />
                          <img
                            src="edit.png"
                            alt="icon"
                            className="action-btn"
                            onClick={() => handleEdit(athlete.id)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
