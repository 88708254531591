import { useNavigate } from "react-router-dom";
import "../Styles/Components/Header.css";
import screenfull from "screenfull";
import { URL } from "../Constants/constant";
export function Header() {
  const navigate = useNavigate();
  const logOut = () => {
    window.localStorage.removeItem("activeUser");
    navigate("/login");
  };
  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };
  const backup = async () => {
    try {
      const response = await fetch(`${URL}export-sql`);
      const sqlData = await response.text();

      // Create a Blob and initiate a download
      const blob = new Blob([sqlData], { type: "application/sql" });

      // Use window.URL.createObjectURL or fallback to global URL.createObjectURL
      const createObjectURL = window.URL
        ? window.URL.createObjectURL
        : URL.createObjectURL;

      const downloadLink = createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = "gym.sql";
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);

      // Check if window.URL.createObjectURL was used and revokeObjectURL accordingly
      if (window.URL) {
        window.URL.revokeObjectURL(downloadLink);
      } else {
        URL.revokeObjectURL(downloadLink);
      }
    } catch (error) {
      console.error("Error exporting SQL file:", error);
    }
  };
  return (
    <div className="header-container">
      <img
        src="fullScreen.png"
        alt="off"
        className="icons"
        onClick={toggleFullscreen}
      />
      <img src="backup.png" alt="off" className="icons" onClick={backup} />
      <span className="brand">سیستم مدیریتی جیم پاور</span>
      <img src="turn-off.png" alt="off" className="off" onClick={logOut} />
    </div>
  );
}
