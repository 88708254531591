import { useEffect } from "react";
import { Sidebar } from "../Components/Sidebar";
import { Header } from "../Components/Header";
import "../Styles/Pages/Home.css";
import { useNavigate } from "react-router-dom";
export function About() {
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        <div className="about-container">
          <div className="about-content">
            <h2 className="about-heading">معرفی:</h2>
            <p className="about-description">
              سلام من یوسف هستم, من یک توسعه دهنده وب با تجربه عمیق در زبان های
              برنامه نویسی فرانت اند هستم. به طور خلاصه، من سیستم های مدیریتی و
              وب‌سایت‌هایی ایجاد می‌کنم که به سازمان‌ها کمک می‌کنند تا چالش‌های
              تجاری را برطرف کنند و نیازهایشان را برآورده کنند. من همه چیز را از
              ساخت وب سایت و طرح بندی گرفته تا میزبانی وب و معماری امنیتی شرکت
              ها را مدیریت می کنم. تخصص من در برنامه های وب فرانت اند نهفته است
              و زبان های اصلی در پشته فناوری من جاوا اسکریپت، ریکت هستند. من یک
              یادگیرنده مادام العمر هستم (در حال حاضر یک دوره آموزشی در زمینه
              ساخت برنامه های موبایل با React Native می گذرانم!) و عاشق خواندن،
              اجرا و یافتن دوستان جدید هستم.
            </p>
            <div className="about-row">
              <div className="about-section">
                <h2 className="about-heading">تحصیلات:</h2>
                <ul className="list">
                  <li>
                    در سال های 2015 تا 2019 زبان انگلیسی و کامپوتر را آموزش دیدم
                  </li>
                  <li>
                    در سال 2019 از لیسه عالی سلطان غیاث الدین غوری فارغ شدم
                  </li>
                  <li>
                    با کسب نمره خوب (305) در آزمون کانکور توانستم در دانشکده
                    علوم کامپیوتر موفق شوم.
                  </li>
                  <li>در سال 2022 از پوهنتون هرات فارغ التحصیل شدم</li>
                </ul>
              </div>
              <div className="about-section">
                <h2 className="about-heading">تجربیات:</h2>
                <ul className="list">
                  <li>2 سال سابقه کار به عنوان برنامه نویس</li>
                  <li>برنامه نویسی را به عنوان توسعه دهنده فرانت اند </li>
                  <li>بهترین UI برای طراحی وب سایت </li>
                  <li>دانش تخصصی زبان های فرانت اند</li>
                  <li> سازماندهی صحیح اطلاعات</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="profile">
            <img src="yousuf.jpg" alt="yousuf" className="profile-image" />
            <div className="profile-details">
              <h1>محمد یوسف محمدی</h1>
              <span>برنامه نویس</span>
              <div className="profile-row">
                <img src="phone.png" alt="mobile" className="profile-icon" />
                <span>0790883362</span>
              </div>
              <div className="profile-row">
                <img src="gmail.png" alt="mobile" className="profile-icon" />
                <span>yousufmohammadi3222@gmail.com</span>
              </div>
              <div className="profile-row">
                <img src="website.png" alt="mobile" className="profile-icon" />
                <a
                  href="https://yousufmohammadi.github.io/profile/"
                  target="_blank"
                  className="website-link"
                >
                  دیدن ویبسایت
                </a>
              </div>
              <div className="profile-row">
                <a href="https://t.me/Mh_Yousuf" target="_blank">
                  <img
                    src="telegram.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="youtube.com/@protips1157" target="_blank">
                  <img
                    src="youtube.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a
                  href="https://www.facebook.com/yousuf.mohammadi.71"
                  target="_blank"
                >
                  <img
                    src="facebook.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
                <a href="https://wa.me/93790883362" target="_blank">
                  <img
                    src="whatsapp.png"
                    alt="mobile"
                    className="profile-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
