import { useNavigate } from "react-router-dom";
import "../Styles/Pages/Login.css";
import { useEffect, useState } from "react";
import axios from "axios";
// import { Loading } from "./Loading";
import { URL } from "../Constants/constant";
export function Login() {
  // const [users, setUsers] = useState([]);
  // const [isloader, setIsloader] = useState(false);
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (JSON.parse(activeUser)) {
      navigate("/home");
    }
    // loadUsers();
    // const username = localStorage.setItem("username","yousufmohammadi")
    // const password = localStorage.setItem("password","yousuf123")
  }, []);
  const navigate = useNavigate();
  // const loadUsers = async () => {
  //   try {
  //     const users = await axios.get(`${URL}login`);
  //     if (users) {
  //       setUsers(users.data);
  //       console.log("user", users);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleLogin = async (e) => {
    e.preventDefault();
    const loginData = {
      userName: e.target.userName.value,
      password: e.target.password.value,
    };
    if (loginData.userName == "" || loginData.password == "") {
      document.getElementById("userName").style.border = "1px solid #FF4155";
      document.getElementById("password").style.border = "1px solid #FF4155";
      setTimeout(() => {
        document.getElementById("userName").style.borderColor = "#dde1e7";
        document.getElementById("password").style.borderColor = "#dde1e7";
      }, 2000);
      return;
    }
    try {
      // setIsloader(true);
      const userLogin = await axios.post(`${URL}login`, loginData);
      if (userLogin.data[0]) {
        window.localStorage.setItem(
          "activeUser",
          JSON.stringify(userLogin.data[0])
        );
        console.log("userLogin", userLogin.data[0]);
        navigate("/home");
      } else {
        document.getElementById("userName").style.border = "1px solid #FF4155";
        document.getElementById("password").style.border = "1px solid #FF4155";
        setTimeout(() => {
          document.getElementById("userName").style.borderColor = "#dde1e7";
          document.getElementById("password").style.borderColor = "#dde1e7";
        }, 2000);
      }
    } catch (error) {
      // setIsloader(false);
      console.log(error);
    }
  };
  return (
    <div className="login-container">
      <form action="#" onSubmit={handleLogin}>
        <div class="login-form">
          <div class="form-group">
            <img
              src="profile-user.png"
              alt="profile-user"
              class="login-image"
            />
          </div>
          <div class="form-group">
            <span class="login-header">ورود به سیستم</span>
          </div>
          <div class="form-group">
            <img src="email.png" alt="email" class="login-icon" />
            <input
              type="text"
              name="userName"
              id="userName"
              placeholder="نام کاربری تان را وارد کنید"
              class="login-input"
              autoComplete="off"
            />
          </div>

          <div class="form-group">
            <img src="password.png" alt="password" class="login-icon" />
            <input
              type="password"
              name="password"
              id="password"
              placeholder=" پسورد تان را وارد کنید"
              class="login-input"
            />
          </div>
          <div class="form-group">
            <input type="submit" value="وارد شوید" class="login-submit" />
          </div>
          {/* <div className="login-loader">{isloader && <Loading />}</div> */}
          <div class="form-group">
            <a className="login-link" href="#">
              {" "}
              {/* پسورد تان را فراموش کردید؟ */}
            </a>
            {/* <a className="login-link" href="#">Sign up</a> */}
          </div>
        </div>
      </form>
    </div>
  );
}
