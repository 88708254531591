import { useEffect, useState } from "react";
import { Header } from "../Components/Header";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import "../Styles/Pages/Athlete.css";
import { URL } from "../Constants/constant";
import { useNavigate } from "react-router-dom";
export function Roznamcha() {
  let sum = 0;
  const [total, setTotal] = useState(sum);
  const [roznamchas, setRoznamchas] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDark, setShowDark] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    loaderRoznamchas();
    todayDate();
  }, [refresh]);
  const loaderRoznamchas = async () => {
    try {
      const roznamcha = await axios.get(`${URL}roznamcha`);
      if (roznamcha) {
        setRoznamchas(roznamcha.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const todayDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  };
  const addAthleteHandler = async (e) => {
    e.preventDefault();
    setShowDark(!showDark);
    setShowPopup(!showPopup);
    const newAthlete = {
      firstName: e.target.firstName.value,
      fatherName: e.target.fatherName.value,
      lastName: e.target.lastName.value,
      age: Number(e.target.age.value),
      phone: e.target.phone.value,
      address: e.target.address.value,
      fess: Number(e.target.fees.value),
      startDate: currentDate,
      status: "active",
    };
    try {
      await axios.post(`${URL}insertAthlete`, newAthlete);
      setRefresh(!refresh);
      console.log("newAthlete", newAthlete);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        {showDark && <div className="dark"></div>}
        {/* add athlete */}
        {showPopup && (
          <div className="add-athlete">
            <form onSubmit={addAthleteHandler}>
              <div className="addPopup-header">
                <span>موجودی صندوق</span>
                <img
                  className="close"
                  src="close.png"
                  alt="close"
                  onClick={() => {
                    setShowDark(!showDark);
                    setShowPopup(!showPopup);
                  }}
                />
              </div>
              <div className="addPopup-body">
                <input
                  type="disable"
                  className="text-input"
                  value={total + " " + "افغانی"}
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                />
              </div>
            </form>
          </div>
        )}
        <div className="content-header">
          <input
            type="search"
            id="search"
            name="search"
            placeholder="نام ورزشکار را جستجو کنید ..."
            className="search"
            autoComplete="off"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            name="status"
            id="status"
            className="select-btn"
          >
            <option value="">همه حالات</option>
            <option value="ATHLETE">ثبت ورزشکار</option>
            <option value="CLOSET">ثبت صندوق</option>
            <option value="FEES">فیس ورزشکار</option>
            <option value="CLOSETFESS">فیس صندوق</option>
            <option value="EXPEND">مصارف</option>
          </select>
          <button
            className="add-btn"
            onClick={() => {
              setShowDark(!showDark);
              setShowPopup(!showPopup);
            }}
          >
            موجودی 🗳
          </button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>ردیف</th>
                <th>نام</th>
                <th>نوعیت</th>
                <th>مبلغ</th>
                <th>تاریخ ثبت</th>
              </tr>
            </thead>
            <tbody>
              {roznamchas.map((roznamcha, index) => {
                console.log("sum", sum);
                if (roznamcha.type == "EXPEND") {
                  sum -= roznamcha.amount;
                } else {
                  sum += roznamcha.amount;
                }
                if (
                  roznamcha.fullName?.includes(search) ||
                  roznamcha.type?.includes(search)
                ) {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{roznamcha.fullName}</td>
                      <td>
                        <span
                          className={
                            roznamcha.type == "ATHLETE" ||
                            roznamcha.type == "FEES" ||
                            roznamcha.type == "CLOSETFESS" ||
                            roznamcha.type == "CLOSET"
                              ? "active"
                              : "disActive"
                          }
                        >
                          {roznamcha.type == "ATHLETE"
                            ? "ثبت ورزشکار"
                            : roznamcha.type == "CLOSET"
                            ? "ثبت صندوق"
                            : roznamcha.type == "FEES"
                            ? "فیس ورزشکار"
                            : roznamcha.type == "CLOSETFESS"
                            ? "فیس صندوق"
                            : "مصرف"}
                        </span>
                      </td>
                      <td>{roznamcha.amount}</td>
                      <td>{roznamcha.date.slice(0, 10)}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
