import { useEffect, useState } from "react";
import axios from "axios";
import { Sidebar } from "../Components/Sidebar";
import { Header } from "../Components/Header";
import "../Styles/Pages/Home.css";
import { CounterCard } from "../Components/CounterCard";
import { useNavigate } from "react-router-dom";
import { URL } from "../Constants/constant";
export function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [athlete, setAthlete] = useState([]);
  const [closet, setCloset] = useState([]);
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    const activeUser = window.localStorage.getItem("activeUser");
    if (!JSON.parse(activeUser)) {
      navigate("/login");
    }
    users();
    athletes();
    closets();
    equpments();
  }, []);
  const users = () => {
    axios
      .get(`${URL}users`)
      .then((res) => setUser(res.data))
      .catch((err) => console.log(err));
  };
  const athletes = () => {
    axios
      .get(`${URL}athlete`)
      .then((res) => setAthlete(res.data))
      .catch((err) => console.log(err));
  };
  const closets = () => {
    axios
      .get(`${URL}closet`)
      .then((res) => setCloset(res.data))
      .catch((err) => console.log(err));
  };
  const equpments = () => {
    axios
      .get(`${URL}equipments`)
      .then((res) => setEquipment(res.data))
      .catch((err) => console.log(err));
  };
  return (
    <div className="container">
      <Header />
      <Sidebar />
      <div className="content">
        <div className="counter-container">
          <CounterCard
            count={athlete.length}
            title="ورزشکاران"
            icon="athlete.png"
            path = "/athlete"
          />
          <CounterCard count={user.length} title="کاربران" icon="users.png" path="/users"/>
          <CounterCard
            count={closet.length}
            title="صندوق ها"
            icon="closets.png"
            path="/closet"
          />
          <CounterCard
            count={equipment.length}
            title="وسایل"
            icon="vahical.png"
            path="/equipment"
          />
        </div>
      </div>
    </div>
  );
}
