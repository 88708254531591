import { useNavigate } from "react-router-dom";
import "../Styles/Components/CounterCard.css";
export function CounterCard(props) {
  const navigate = useNavigate()
  return (
    <div className="counterCard-container" onClick={()=>navigate(props.path)}>
      <span className="counterCard-title">{props.title}</span>
      <img src={props.icon} alt="logo" className="counterCard-icon"/>
      <span className="counterCard-counter">{props.count}</span>
    </div>
  );
}
