import { useLocation } from "react-router-dom";
import "../Styles/Components/Sidebar.css";
import { SidebarBtn } from "./SidebarBtn";
import { useEffect, useState } from "react";
export function Sidebar() {
  const location = useLocation();
  const [userDetail, setUserDetails] = useState();
  useEffect(() => {
    var activeUser = window.localStorage.getItem("activeUser");
    const loginedUser = JSON.parse(activeUser);
    setUserDetails(loginedUser);
  }, []);
  return (
    <div className="sibar-container">
      <div className="logo-container">
        <img src="logo.png" alt="logo" />
        <span>gym power</span>
      </div>
      <div className="userDetail-container">
        <img src="userImage.png" alt="userImage" />
        <span>{userDetail?.firstName + " " + userDetail?.lastName}</span>
      </div>
      <SidebarBtn />
    </div>
  );
}
