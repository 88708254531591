import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Login } from "./Pages/Login";
import "./App.css";
import { Athlete } from "./Pages/Athlete";
import { User } from "./Pages/User";
import { Closet } from "./Pages/Closet";
import { Equipment } from "./Pages/Equipment";
import { Payment } from "./Pages/Payment";
import { Expend } from "./Pages/Expend";
import { Roznamcha } from "./Pages/Roznamcha";
import { ClosetPayment } from "./Pages/ClosetPayment";
import { About } from "./Pages/About";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Fragment>
              <Home />
            </Fragment>
          }
        />
        <Route
          path="/home"
          element={
            <Fragment>
              <Home />
            </Fragment>
          }
        />
        <Route
          path="/login"
          element={
            <Fragment>
              <Login />
            </Fragment>
          }
        />
        <Route
          path="/athlete"
          element={
            <Fragment>
              <Athlete />
            </Fragment>
          }
        />
        <Route
          path="/users"
          element={
            <Fragment>
              <User />
            </Fragment>
          }
        />
        <Route
          path="/closet"
          element={
            <Fragment>
              <Closet />
            </Fragment>
          }
        />
        <Route
          path="/equipment"
          element={
            <Fragment>
              <Equipment />
            </Fragment>
          }
        />
        <Route
          path="/payment"
          element={
            <Fragment>
              <Payment />
            </Fragment>
          }
        />
        <Route
          path="/closetPayment"
          element={
            <Fragment>
              <ClosetPayment />
            </Fragment>
          }
        />
        <Route
          path="/expend"
          element={
            <Fragment>
              <Expend />
            </Fragment>
          }
        />
        <Route
          path="/roznamcha"
          element={
            <Fragment>
              <Roznamcha />
            </Fragment>
          }
        />
        <Route
          path="/about"
          element={
            <Fragment>
              <About />
            </Fragment>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
